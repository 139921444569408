<template>
  <xzd-list-page ref="xzdListPage" module-name="page" :get-list-func="getList" :queryParam="queryParam" can-select :showImportExcelButton="false" :showExportExcelButton="false">
    <div slot="searchBox">
      <page-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <show-space>
        <save-page-button @xzdListPageReload="xzdListPageReload"/>
        <delete-page-button :ids="selectedRows.map(v => v.id)" @xzdListPageReload="xzdListPageReload"/>
        <add-from-market-button @xzdListPageReload="xzdListPageReload"/>
      </show-space>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column prop="name" :label="$t('page.name')"/>
      <el-table-column :label="$t('common.operate')" width="450" align="center">
        <template slot-scope="scope">
          <show-space>
            <save-page-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
            <delete-page-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload"/>
            <set-market-group-button v-if="$perm('SetMarketGroupButton')" :id="scope.row.id" @xzdListPageReload="xzdListPageReload"/>
            <el-button type="primary" @click="edit(scope.row.id)" size="mini">{{$t('page.edit')}}</el-button>
            <el-button type="primary" @click="show(scope.row.id)" size="mini">{{$t('page.preview')}}</el-button>
          </show-space>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/page";
import PageListSearchBox from "./PageListSearchBox";
import SavePageButton from "../buttons/SavePageButton";
import DeletePageButton from "../buttons/DeletePageButton";
import SetMarketGroupButton from "@/views/page/buttons/SetMarketGroupButton";
import AddFromMarketButton from "@/views/page/buttons/AddFromMarketButton";

export default {
  name: "PageList",
  components: {AddFromMarketButton, SetMarketGroupButton, DeletePageButton, SavePageButton, PageListSearchBox},
  data() {
    return {
      queryParam: {},
      getList,
    }
  },
  mounted() {
    console.log('PageList - mounted')
  },
  methods: {
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    },
    edit(id) {
      window.open(process.env.BASE_URL + "/edit.html?id=" + id)
    },
    show(id) {
      window.open(process.env.BASE_URL + "/show.html?id=" + id)
    }
  }
}
</script>
<style scoped>
</style>